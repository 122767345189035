<template>
  <div class="contanier">
    <van-icon class="arrow" @click="$router.go(-1)" size=".35rem" name="arrow-left"/>
    <div class="authen">个人实名认证</div>
    <div class="info">实名认证后, 您被企业入职的机率会更高</div>
    <div class="tab">
			<div class="nickName">姓名 :</div>
			<input  class="input" v-model="username" type="text" placeholder="请输入您的姓名">
		</div>
     <div class="tab">
			<span class="nickName">身份证号 :</span>
			<input  class="input" v-model="idCard" type="text" placeholder="请输入您的身份证号">
		</div>
    <div class="submit" @click="submit">提交</div>
  </div>
</template>
<script>
import { Toast } from 'vant'
export default {
  data(){
    return {
      username:"",
      idCard:""
    }
  },
  methods:{
    submit(){
      let { username , idCard} = this
      let reg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
      let bool = reg.test(idCard)
      if(bool == true){
        this.$http.post("/user/v1/personal_info/realNameAuthentication",{reqType:"realNameAuthentication",name:username,idCard:idCard})
      .then((res)=>{
        let response = JSON.parse(res.data)
        if(response.code == 0){
          Toast.success("保存成功")
          setTimeout(()=>{
            this.$router.push("./usermsg")
          },500)
        }else{
          Toast.fail(response.msg)
        }
        })
      .catch((e)=>{console.log(e)})
      }else{
        Toast("请输入正确的身份证号码")
      }
      
   }
  }
}
</script>
<style scoped>
.submit{
  width: 7.02rem;
height: 0.97rem;
background: linear-gradient(90deg, #FF4800 0%, #FC9E46 100%);
border-radius: 0.16rem;
line-height: 0.97rem;
text-align: center;
color:#fff;
margin:0 auto;
margin-top:5rem;
font-size: 0.3rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
}
.contanier{
	position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
}
.input{
	border:none;
  width:4rem;
	font-size: 0.3rem;
	margin-left:0.01rem;
	font-family: PingFangSC-Regular, PingFang SC;
	color: #6F6F6F;
}
.tab{
	background: #FFFFFF;
	border-bottom: 0.02rem solid #ddd;
	margin:0  0.5rem;
	margin-bottom: 0.2rem;
  display:flex;
  align-items: center;
  margin-bottom:0.4rem;
  padding-bottom:0.3rem;
}
.nickName{
 
	font-size: 0.3rem;
	font-family: PingFangSC-Regular, PingFang SC;
	color: #333333;
	margin-right:0.05rem;
}
.info{
	width: 5.17rem;
	height: 0.33rem;
	font-size: 0.24rem;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #F55814;
	line-height: 0.33rem;
	margin-top:0.24rem;
	margin-left:0.5rem;
	margin-bottom:0.9rem;
}
.authen{
  font-size: 0.42rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-left:0.5rem;
  margin-top:0.6rem;
}
.arrow{
  margin-left:0.32rem;
  margin-top:0.5rem;
}
</style>